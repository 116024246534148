<template>
  <div id="stopOrderList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>停车订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">订单号:</span>
            <el-input
              placeholder="请输入订单号"
              v-model="filtrate.orderId"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <span class="label">停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              placeholder="请选择"
              @change="
                getIssuerList();
                getList(1);
              "
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">商户:</span>
            <el-select
              v-model="filtrate.merchantId"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in issuerList"
                :key="item.issuerId"
                :label="item.merchantName"
                :value="item.issuerId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">支付平台:</span>
            <el-select
              v-model="filtrate.platformType"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_platformType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li v-if="filtrate.platformType === '现金支付'">
            <span class="label">放行类型:</span>
            <el-select
              v-model="filtrate.releaseType"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_releaseType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">订单状态:</span>
            <el-select
              v-model="filtrate.status"
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in select_orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="endTimeChange"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              type="primary"
              @click="exportExcel"
              v-if="buttonList.includes('stopOrderList-export')"
              >导出报表
            </el-button>
          </li>
          <li>
            <el-button
              type="primary"
              @click="exportList"
              v-if="buttonList.includes('stopOrderList-exportList')"
              >自定义导出列表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
        row-key="orderId"
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :key="table.loading"
      >
        <el-table-column prop="orderId" label="订单号" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column prop="platformType" label="支付平台" align="center">
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '订单生成'"
              class="payStatus shengcheng"
              >订单生成</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              class="payStatus daizhifu"
              >待支付</span
            >
            <span
              v-if="scope.row.orderStatus === '支付成功'"
              class="payStatus chenggong"
              >{{
                scope.row.releaseType === "异常放行"
                  ? "异常放行" +
                    (scope.row.releaseReason
                      ? `(${scope.row.releaseReason})`
                      : "")
                  : "支付成功"
              }}</span
            >
            <span
              v-if="scope.row.orderStatus === '支付失败'"
              class="payStatus shibai"
              >支付失败</span
            >
            <span
              v-if="scope.row.orderStatus === '交易关闭'"
              class="payStatus awaitPay"
              >交易关闭</span
            >
            <span
              v-if="scope.row.orderStatus === '交易取消'"
              class="payStatus awaitPay"
              >交易取消</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              class="payStatus tuikuan"
              >已退款</span
            >
            <span
              v-if="scope.row.orderStatus === '部分退款'"
              class="payStatus tuikuan"
              >部分退款</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="totalPrice" label="总金额" align="center">
        </el-table-column>
        <el-table-column
          prop="shopName"
          label="发券商家"
          align="center"
          width="70px"
        >
        </el-table-column>
        <el-table-column
          width="120px"
          prop="couponPrice"
          label="优惠券(系统/商户)"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payAmount"
          label="实付金额"
          align="center"
          width="70px"
        >
        </el-table-column>
        <el-table-column
          prop="walletAmount"
          label="钱包支付"
          align="center"
          width="70px"
        >
        </el-table-column>
        <el-table-column
          prop="freeCarAmount"
          label="挂单金额"
          align="center"
          width="70px"
        >
        </el-table-column>
        <el-table-column
          prop="invoiceStatus"
          label="开票状态"
          align="center"
          width="70px"
        >
        </el-table-column>
        <el-table-column
          prop="serviceStartTime"
          label="入场时间"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="serviceEndTime" label="出场时间" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" align="center">
        </el-table-column>
        <el-table-column prop="orderTime" label="订单时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                @click="refund(scope.row.orderId)"-->
            <!--                v-if="-->
            <!--                buttonList.includes('stopOrderList-refund') &&-->
            <!--                scope.row.orderStatus === '支付成功' &&-->
            <!--                scope.row.refundEnable === '否' &&-->
            <!--                scope.row.payAmount > 0-->
            <!--              "-->
            <!--            >退款-->
            <!--            </el-button>-->

            <el-button
              type="text"
              @click="requestRefund(scope.row.orderId)"
              :disabled="
                scope.row.refundStatus === '申请退款中' ||
                scope.row.refundStatus === '已同意'
              "
              v-if="
                buttonList.includes('stopOrderList-requestRefund') &&
                scope.row.refundEnable === '是'
              "
              >{{ scope.row.refundStatusText }}
            </el-button>
            <el-button
              type="text"
              @click="parkingDetail(scope.row.parkingRecordId)"
              v-if="
                buttonList.includes('stopOrderList-parkingDetailDialog') &&
                scope.row.isParent === '是'
              "
              >停车详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <exportDialog ref="exportDialog" />
    <exportListDialog ref="exportListDialog" />
    <parkingDetailDialog ref="parkingDetailDialog" />
    <requestRefundDialog ref="requestRefundDialog" @onsuccess="getList" />
  </div>
</template>
<script>
export default {
  components: {
    exportDialog: () => import("./exportDialog.vue"),
    exportListDialog: () => import("./exportListDialog.vue"),
    requestRefundDialog: () => import("./requestRefundDialog.vue"),
    parkingDetailDialog: () =>
      import("./parkingDetailDialog/parkingDetailDialog.vue"),
  },
  data() {
    return {
      issuerList: [],
      filtrate: {
        keyword: "",
        orderId: "",
        merchantId: "",
        parkingLotId: "",
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
        platformType: "",
        releaseType: "",
        status: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    if (this.$route.params.keyword) {
      this.filtrate.orderId = this.$route.params.keyword;
    }
    this.getList();
    this.getIssuerList();
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    endTimeChange(value) {
      Object.assign(this.filtrate, {
        endTime: value
          ? this.$moment(this.$moment(value).format("YYYY-MM-DD 23:59:59"))
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : null,
      });
      this.getList(1);
    },
    async getIssuerList(parkingLotId = this.filtrate.parkingLotId) {
      try {
        let res = await this.$http.post("/statistics/merchant/list", {
          parkingLotId,
        });
        if (res.code === 0) {
          this.issuerList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    parkingDetail(parkingRecordId) {
      this.$refs.parkingDetailDialog.parkingRecordId = parkingRecordId;
      this.$refs.parkingDetailDialog.show = true;
    },
    async load(tree, treeNode, resolve) {
      let res = await this.$http.post("/order/list", {
        parentOrderId: tree.orderId,
      });
      if (res.code === 0) {
        for (let item of res.data.list) {
          Object.assign(item, this.coverData(item));
        }
        resolve(res.data.list);
      } else {
        this.$message.error(res.msg);
      }
    },
    exportExcel() {
      this.$refs.exportDialog.form = {
        time: [
          this.filtrate.startTime ? this.filtrate.startTime : "",
          this.filtrate.endTime ? this.filtrate.endTime : "",
        ],
        orderStatus: this.filtrate.status,
        platformType: this.filtrate.platformType,
        releaseType: this.filtrate.releaseType,
        parkingLotId: this.filtrate.parkingLotId,
      };
      this.$refs.exportDialog.handleShow();
    },
    exportList() {
      this.$refs.exportListDialog.form = Object.assign(
        this.$refs.exportListDialog.form,
        {
          time: [
            this.filtrate.startTime ? this.filtrate.startTime : "",
            this.filtrate.endTime ? this.filtrate.endTime : "",
          ],
          parkingLotId: this.filtrate.parkingLotId,
          platformType: this.filtrate.platformType,
          releaseType: this.filtrate.releaseType,
          orderStatus: this.filtrate.status,
        }
      );
      this.$refs.exportListDialog.handleShow();
    },
    refund(orderId) {
      this.$confirm("此操作将完成退款, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: "正在退款中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let res = await this.$http.post(`/order/refund/${orderId}`);
          if (res.code === 0) {
            this.$message.success("退款成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
          loading.close();
        })
        .catch(() => {});
    },
    requestRefund(orderId) {
      this.$refs.requestRefundDialog.orderId = orderId;
      this.$refs.requestRefundDialog.show = true;
    },
    reset() {
      this.filtrate = {
        keyword: "",
        orderId: "",
        parkingLotId: "",
        merchantId: "",
        startTime: "",
        endTime: "",
        platformType: "",
        releaseType: "",
        status: "",
      };
      this.getList(1);
    },
    coverData(item) {
      let data = { ...item };
      data.couponPrice = `${data.couponPrice}(${data.ticketUseValue}/${data.purseUseValue})`;
      data.hasChildren = item.hasChildren === "是";
      data.serviceStartTime = this.$moment(item.serviceStartTime).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      data.serviceEndTime = this.$moment(item.serviceEndTime).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      data.orderTime = this.$moment(item.orderTime).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      data.payTime =
        item.payTime &&
        this.$moment(item.payTime).format("yyyy-MM-DD HH:mm:ss");
      switch (item.refundStatus) {
        case "申请退款中":
          data.refundStatusText = "等待审批";
          break;
        case "已同意":
          data.refundStatusText = "已同意";
          break;
        case "已拒绝":
          data.refundStatusText = "已拒绝(重新申请)";
          break;
        default:
          data.refundStatusText = "申请退款";
      }
      return data;
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/order/list", {
          ...this.filtrate,
          platformType:
            this.filtrate.platformType === ""
              ? null
              : this.filtrate.platformType,
          releaseType:
            this.filtrate.platformType === "现金支付"
              ? this.filtrate.releaseType === ""
                ? null
                : this.filtrate.releaseType
              : null,
          status: this.filtrate.status === "" ? null : this.filtrate.status,
          orderId: this.filtrate.orderId === "" ? null : this.filtrate.orderId,
          merchantId:
            this.filtrate.merchantId === "" ? null : this.filtrate.merchantId,
          pageNum: page,
          pageSize: this.table.pageSize,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            Object.assign(item, this.coverData(item));
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#stopOrderList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .exportBtn {
            background-color: #ff9747;
            border-color: #ff9747;

            i {
              font-size: 14px;
            }
          }
        }
      }
    }

    .el-table {
      .el-table__body {
        .payStatus {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $main-color;
        }

        .shengcheng {
          color: #5f8cee;

          &::before {
            background-color: #5f8cee;
          }
        }

        .daizhifu {
          color: #e5ee67;

          &::before {
            background-color: #e5ee67;
          }
        }

        .chenggong {
          color: #1da05e;

          &::before {
            background-color: #1da05e;
          }
        }

        .notPay {
          color: #ff0000;

          &::before {
            background-color: #ff0000;
          }
        }

        .awaitPay {
          color: #8d8d8d;

          &::before {
            background-color: #8d8d8d;
          }
        }

        .tuikuan {
          color: #eb89cd;

          &::before {
            background-color: #eb89cd;
          }
        }
      }
    }
  }
}
</style>
